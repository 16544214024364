import { createSlice } from "@reduxjs/toolkit";
import { editProfileServices } from "../../Services/FormDataServices";
import { tutorDetailsServices } from "../Thunk/profileServices";
import { classOverViewDetailsServices } from "../Thunk/dashboardServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  classOverViewDetails:{},
};

const dashboardSlice = createSlice({
  name: "MY_SESSION_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(classOverViewDetailsServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(classOverViewDetailsServices.fulfilled, (state, action) => {    
      return { ...state,classOverViewDetails:action?.payload?.result,loading: false };
    });
    builder.addCase(classOverViewDetailsServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
        
  },
});

export default dashboardSlice.reducer;
