// Types Imports
import { SET_ALERT, REMOVE_ALERT } from '../Types';

// Initial State
const initialState = [];

// Defining Reducer Function
export const alertState = (state = initialState, action) => {
	// Destructure Action
	const { type, payload } = action;

	switch (type) {
		case SET_ALERT: {
			return [...state, action.payload];
		}

		case REMOVE_ALERT: {
			return state.filter((alert) => {
				return alert.id !== payload;
			});
		}

		// Default Case
		default: {
			return state;
		}
	}
};
