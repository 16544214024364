import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios.js";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";


export const myEarningListServices = createAsyncThunk(
    "myEarningListServices",
    async (paremeter) => {
        let {status,pageNumber}=paremeter
      // console.log(payload);
      try {
        let url = `${BASE_URL}/teacher/teacher-class-details-list?listType=${status}&itemsPerPage=10&pageNumber=${pageNumber}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        //   console.log(error);
        //   console.log(error);
        handleError(error);
        throw error;
      }
    }
  );



  export const myEarningOverViewServices = createAsyncThunk(
    "myEarningOverViewServices",
    async (payload) => {
        // let {status,pageNumber}=paremeter
      try {
        let url = `${BASE_URL}/teacher/teacher-earning-overview?classType=${payload?.classType}&startDateTimestamp=${payload?.startDateTimestamp}&endDateTimestamp=${payload?.endDateTimestamp}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        //   console.log(error);
        //   console.log(error);
        // handleError(error);
        throw error;
      }
    }
  );


  export const earningBreakdownInvoiceServices = createAsyncThunk(
    "earningBreakdownInvoiceServices",
    async (payload) => {
        // let {status,pageNumber}=paremeter
      try {
        let url = `${BASE_URL}/teacher/teacher-invoice?startDateTimestamp=${payload?.startDateTimestamp}&endDateTimestamp=${payload?.endDateTimestamp}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        //   console.log(error);
        //   console.log(error);
        handleError(error);
        throw error;
      }
    }
  );