import { createSlice } from "@reduxjs/toolkit";
import { classFeedBackQuestionServices, closeSlotServices, demoFeedBackServices, endClassServices, endRecordingServices, joinClassTimeServices, myClassListServices, openSlotServices, sessionCancelCountServices, startRecordingServices } from "../Thunk/myClassServices";
import { sucessToast } from "../../Utils/Helper";

const initialState = {
  data: null,
  loading: false,
  error: null,
  myClassList: [],
  sessionCancelCount:null
};

const myClassSlice = createSlice({
  name: "MY_SESSION_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(myClassListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(myClassListServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
    
      return { ...state, myClassList: action.payload.result, loading: false };
    });
    builder.addCase(myClassListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    builder.addCase(joinClassTimeServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(joinClassTimeServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
      return { ...state, loading: false };
    });
    builder.addCase(joinClassTimeServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(endClassServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(endClassServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
      return { ...state,  loading: false };
    });
    builder.addCase(endClassServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    builder.addCase(classFeedBackQuestionServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(classFeedBackQuestionServices.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(classFeedBackQuestionServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    builder.addCase(demoFeedBackServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(demoFeedBackServices.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(demoFeedBackServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    builder.addCase(startRecordingServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(startRecordingServices.fulfilled, (state, action) => {
      sucessToast('Recording Start Sucessfully')
      return { ...state,loading: false };
    });
    builder.addCase(startRecordingServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(endRecordingServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(endRecordingServices.fulfilled, (state, action) => {
      sucessToast('Recording End ')
      return { ...state,loading: false };
    });
    builder.addCase(endRecordingServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
    

    builder.addCase(openSlotServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(openSlotServices.fulfilled, (state, action) => {
      return { ...state,loading: false };
    });
    builder.addCase(openSlotServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
    
    builder.addCase(closeSlotServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(closeSlotServices.fulfilled, (state, action) => {
      return { ...state,loading: false };
    });
    builder.addCase(closeSlotServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
    builder.addCase(sessionCancelCountServices.pending, (state) => {
      return { ...state, loading: true,sessionCancelCount:null };
    });
    builder.addCase(sessionCancelCountServices.fulfilled, (state, action) => {
      return { ...state,loading: false ,sessionCancelCount:action.payload.result};
    });
    builder.addCase(sessionCancelCountServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});

export default myClassSlice.reducer;
