import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStorageValue } from "./LocalStorageServices";
import { BASE_URL } from "../Utils/Urls";
import axios from "axios";
import { handleError } from "./ErrorHandlerServices";

export const editProfileServices = createAsyncThunk(
    'editProfileServices',
    async (payload) => {
      try {
        const tutorData = getStorageValue("tutorData");


        const url = `${BASE_URL}/teacher/update-teacher-profile`;
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${tutorData?.accessToken}`,
            },
        };
  
        // Make the POST request with axios
        const res = await axios.put(url, payload, config);
        
        // Return the response data
        return res.data;
      } catch (error) {
        // Handle errors
        handleError(error)
        console.error('An error occurred:', error);
        throw error;
      }
    }
  );