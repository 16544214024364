// Alert Types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Authentication
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const VERIFY_USER="VERIFY_USER"

// Theme Types
export const DARK_MODE = 'DARK_MODE';
export const LIGHT_MODE = 'LIGHT_MODE';

export const s = 's';


// Slots 
export const SLOT_TIME_LIST="SLOT_TIME_LIST"
export const TEACHER_SLOT="TEACHER_SLOT"