import user from '../assets/user.png'


// Local Url
// export const BASE_URL='https://stagingapi.englishyaari.com/api'


// export const BASE_URL="https://e991-103-227-70-204.ngrok-free.app/api"

// export const BASE_URL = 'https://8f3b-103-21-184-143.ngrok-free.app/api'

// Produvtion Url
export const BASE_URL="https://production.codingyaari.com/EnglishYaariServerapi"

export const PROFILEURL=user