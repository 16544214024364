import { createSlice } from "@reduxjs/toolkit";
import { editProfileServices } from "../../Services/FormDataServices";
import { tutorDetailsServices } from "../Thunk/profileServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  tutorDetails:{},
};

const profileSlice = createSlice({
  name: "MY_SESSION_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(editProfileServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(editProfileServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
    
      return { ...state,  loading: false };
    });
    builder.addCase(editProfileServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
    


    builder.addCase(tutorDetailsServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(tutorDetailsServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
    
      return { ...state, tutorDetails: action.payload.result, loading: false };
    });
    builder.addCase(tutorDetailsServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});

export default profileSlice.reducer;
