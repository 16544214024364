import React, { } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux/Store";
import App from "./App";
import "./index.css";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

const root = ReactDOM.createRoot(document.getElementById("root"));


const config = { 
  mode: "rtc", codec: "vp8",
  // Enable echo cancellation
  audioProcessing: {
    AEC: true, // Echo cancellation
    AGC: true, // Automatic gain control
    ANS: true, // Automatic noise suppression
    bypassWebAudio:true
  }
};

export const client = AgoraRTC.createClient(config);


const RootComponent = () => {


  return (
    <Provider store={store}>
      <ToastContainer />
      <AgoraRTCProvider client={client}>
        <Router>
          <App />
        </Router>
      </AgoraRTCProvider>
    </Provider>
  );
};

root.render(<RootComponent />);
