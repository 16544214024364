import { toast } from "react-toastify"

export const errorToast=(msg)=>{
    return toast.error(msg,{
      autoClose:1000
    })
  }
  
  export const sucessToast=(msg)=>{
    return toast.success(msg,{
      autoClose:1000
    })
  }


  export const addZero=(val)=>{
    if(val<10){
      return `0${val}`
    }
    else{
      return val
    }
    }

   export  function generateSixDigitString(inputString) {
      // Remove non-alphanumeric characters from the input string
      const cleanedString = inputString.replace(/[^a-zA-Z0-9]/g, '');
    
      let result = '';
      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * cleanedString.length);
        result += cleanedString.charAt(randomIndex);
      }
    
      return result;
    }
    
    export const expertisetag = {
      2: "Grammar",
      3: "Vocabulary",
      4: "IELTS Speaking",
      5: "Interview skills",
      6: "Public Speaking",
      7: "Business English",
      8: "Vocabulary", // Duplicate value
      8: "Grammar", // Duplicate value
    };


    

   export function getOrdinalList(...numbers) {
      return numbers.map((num, index) => {
          let suffix;
          const lastDigit = num % 10;
          const lastTwoDigits = num % 100;
          
          if (lastDigit === 1 && lastTwoDigits !== 11) {
              suffix = 'st';
          } else if (lastDigit === 2 && lastTwoDigits !== 12) {
              suffix = 'nd';
          } else if (lastDigit === 3 && lastTwoDigits !== 13) {
              suffix = 'rd';
          } else {
              suffix = 'th';
          }
  
          return `${num}${suffix}`;
      }).join(', ');
  }
  
  // Example usage:
  // const result = getOrdinalList(1, 2, 3, 4);
  // console.log(result); // Output: "1st, 2nd, 3rd, 4th"
  

  export function getCurrentMonthTimestamps() {
    const now = new Date();

    // Start of the month (e.g., August 1st, 00:00:00)
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
    const startOfMonthTimestamp = startOfMonth.getTime();

    // log
    // End of the month (e.g., August 31st, 23:59:59)
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    const endOfMonthTimestamp = endOfMonth.getTime();

    return {
        startOfMonthTimestamp,
        endOfMonthTimestamp
    };
}

// const timestamps = getCurrentMonthTimestamps();
// console.log(timestamps);



// Function to determine network quality status
export  function getNetworkStatus(networkQuality) {
  const { uplinkNetworkQuality, downlinkNetworkQuality, delay } = networkQuality;

  // Basic conditions to determine network status
  let status = "Poor"; // Default to poor if no other condition is met

  if (uplinkNetworkQuality <= 1 && downlinkNetworkQuality <= 1 && delay < 100) {
    status = "Excellent";
  } else if (uplinkNetworkQuality <= 2 && downlinkNetworkQuality <= 2 && delay < 200) {
    status = "Good";
  } else if (uplinkNetworkQuality <= 3 && downlinkNetworkQuality <= 3 && delay < 300) {
    status = "Average";
  } else {
    status = "Poor";
  }

  return status;
}