import axios from "axios";
import { getStorageValue } from "../Services/LocalStorageServices";

axios.interceptors.request.use(
  (config) => {
    const token = getStorageValue("tutorData");    
    config.headers["Authorization"] = `Bearer ${token?.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.message == "jwt expired") {
    //   localStorage.clear();
    //   store.dispatch({
    //     type: IS_AUTH,
    //     payload: true,
    //   });
    //   emitter.emit("logout");
    //   window.location.href = "/";
    }
    // handleError(error)
    return Promise.reject(error);
    // console.log(error)
    // if(error.response.data.error.message=="Token has been expired. Kindly Relogin"){
    // localStorage.removeItem('token')
    // emitter.emit('logout')
    // }
  }
);

export default axios;




