import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios.js";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const tutorDetailsServices = createAsyncThunk(
  "tutorDetailsServices",
  async () => {
    try {
      let url = `${BASE_URL}/teacher/teacher-profile-details`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);



export const myRatingServices = createAsyncThunk(
  "myRatingServices",
  async () => {
    try {
      let url = `${BASE_URL}/teacher/teacher-review-and-rating`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);