// Package Imports
import { combineReducers } from "redux";

// Reducer Imports
import { alertState } from "./AlertReducer";
import { authState } from "./AuthReducer";
import { openSlotState } from "./SlotReducer";
import mySessionState from "../Slice/myClassSlice";
import earningState from "../Slice/earningSlice";
import profileState from '../Slice/profileSice'
import dashboardState from "../Slice/dashboardSlice";


export default combineReducers({ alertState, authState, openSlotState,mySessionState,earningState,profileState,dashboardState });
