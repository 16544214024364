import React, {  useEffect, lazy, Suspense, useState } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, } from "react-redux";
import {
  getStorageValue,
  setStorageValue,
} from "./Services/LocalStorageServices";
import { REGISTRATION_SUCCESS, VERIFY_USER } from "./Redux/Types";
import { emitter } from "./Utils/EventEmitter";


// Lazy-loaded Components
const AuthRoutes = lazy(() => import("./Routes/AuthRoutes"));
const HomeRoute = lazy(() => import("./Routes/HomeRoute"));

const App = () => {
  // const token=getStorageValue('accessToken')
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  let checkUser = async () => {
    let tutorData = getStorageValue("tutorData");
    console.log(tutorData);
    if (tutorData) {
      if (!tutorData.isVerified) {
        navigate("/createPassword");
      }
      if (tutorData.isVerified) {
        setIsLogin(true);
      } else {
        console.log('yesss');
        dispatch({
          type: VERIFY_USER,
          payload: { isVerified: false },
        });
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // Function to extract token from URL and store it in local storage
    const storeTokenFromUrl = () => {
      const params = new URLSearchParams(location?.search);
      const toke = params.get("token");
      if (toke) {
        let obj = {
          accessToken: toke,
          isVerified:true
        };
        dispatch({
          type: REGISTRATION_SUCCESS,
          payload: { isAuthenticated: true },
        });
        setStorageValue("tutorData", obj);
        setIsLogin(true);
      }
    };

    storeTokenFromUrl();
  },[]);

  useEffect(() => {
    emitter.on("logout", () => {
      checkUser();
      navigate("/");
    });
  }, []);

  // console.log(isAuth);
  const renderComponent = () => {
    // alert('hee')
    if (!isLogin) {
      return <AuthRoutes setIsLogin={setIsLogin} />;
    }
    if (isLogin) {
      return <HomeRoute setIsLogin={setIsLogin} />;
    }
  };



  useEffect(() => {
    checkUser();
  }, []);

  return (
    <div className="Main-Body h-screen bg-background overflow-hidden">
      <Suspense
        fallback={<div className="h-screen w-screen bg-red-50">Loading...</div>}
      >
        <div>{renderComponent()}</div>
      </Suspense>
    </div>
  );
};

export default App;
