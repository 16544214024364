import {  SLOT_TIME_LIST, TEACHER_SLOT } from "../Types";

const initialState = {
  slotTiming:[],
  teacherSlot:[]
  // isLoading:false,
  // isAuthenticated:false
};

// Defining Reducer Function
export const openSlotState = (state = initialState, action) => {
  // Destructure Action
  const { type, payload } = action;

  switch (type) {
    // Default Case
    case SLOT_TIME_LIST: {
      return { ...state, slotTiming: payload };
    }

    case TEACHER_SLOT: {
      return { ...state, teacherSlot: payload };
    }
    default: {
      return state;
    }
  }
};
