import { createSlice } from "@reduxjs/toolkit";
import { myEarningListServices, myEarningOverViewServices } from "../Thunk/earningServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  earningList: [],
  earningOverView:null
};

const earningSlice = createSlice({
  name: "MY_EARNING_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(myEarningListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(myEarningListServices.fulfilled, (state, action) => {
    //   console.log(action.payload,'sksksksks');
    
      return { ...state, earningList: action.payload.result, loading: false };
    });
    builder.addCase(myEarningListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    builder.addCase(myEarningOverViewServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(myEarningOverViewServices.fulfilled, (state, action) => {    
      return { ...state, earningOverView: action.payload.result, loading: false };
    });
    builder.addCase(myEarningOverViewServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
    
  },
});

export default earningSlice.reducer;
